/* eslint-disable camelcase */import React from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
// import Layout from "../../Layouts/Layout";
import Footer from "../../layout/footer";
// import Newblog from "../../components/Newblog";
import Header from "../../layout/header";
import Layout from '../../Layouts-new/Layout';

// import Letsdesign from "../../components/Letsdesign";
// import Newblog from "../../components/Newblog";

gsap.registerPlugin(ScrollTrigger);

function Blog() {

  useGSAP(() => {
    gsap.from(".Topfive-img", {
      xPercent: -100,
      duration: 1
    });
    gsap.from(".Topfive-text", {
      xPercent: 100,
      duration: 1
    });

    gsap.from(".Topfive-title", {
      yPercent: -100,
      duration: 1
    });
  });

  return (

    <Layout>
      <div className="relative overflow-hidden">
        <Helmet>
          <title>Latest Blogs for Web Development, Blockchain Development, Web Design and SEO | Solusent</title>
          <meta
            name="description"
            content="Explore the newest blogs on Web Development, Blockchain Development, Web Design, and SEO. Get valuable advice and stay ahead with Solusent's latest blogs."
          />
          <meta name="robots" content="follow, index, all" />
          <meta
            name="keywords"
            content="latest blogs for Web Development, latest blogs for Web Design, Latest Blog for Blockchain Development"
          />
          <link rel="canonical" href="https://solusent.com/blog" />
          <meta property="og:locale" content="en_GB" />
          <meta property="og:site_name" content="Solusent" />
          <meta property="og:type" content="article" />
          <meta
            property="og:title"
            content="Latest Blogs for Web Development, Blockchain Development, Web Design and SEO | Solusent"
          />
          <meta
            property="og:description"
            content="Explore the newest blogs on Web Development, Blockchain Development, Web Design, and SEO. Get valuable advice and stay ahead with Solusent's latest blogs."
          />
          <meta property="og:url" content="https://solusent.com/blog" />
          <meta
            property="og:image"
            content="https://solusent.com/images/blog/block-chain-blog.jpg"
          />
          <meta
            property="og:image:secure_url"
            content="https://solusent.com/images/blog/block-chain-blog.jpg"
          />
          <meta property="og:image:width" content="438" />
          <meta property="og:image:height" content="247" />
          <meta
            property="article:publisher"
            content="https://www.facebook.com/solusent"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@solusent" />
          <meta
            name="twitter:title"
            content="Latest Blogs for Web Development, Blockchain Development, Web Design and SEO | Solusent"
          />
          <meta
            name="twitter:description"
            content="Explore the newest blogs on Web Development, Blockchain Development, Web Design, and SEO. Get valuable advice and stay ahead with Solusent's latest blogs."
          />
          <meta
            name="twitter:image"
            content="https://solusent.com/images/blog/block-chain-blog.jpg"
          />
        </Helmet>
        {/* <GradientBalls /> */}
        {/* <div className="top-0 w-full relative">
        <Header />
      </div> */}
        <div className=" bg-background_body overflow-hidden py-16">
          {/* <Layout> */}
          <div className="mx-auto max-w-[1200px] xl:px-0 px-5  flex items-center justify-center flex-col  mb-[40px]">
            <div className=" w-full ">
              <h2 className="Topfive-title text-left capitalize  ">
                Top Blog of the day
              </h2>

              <div className="992px:flex mt-[39px] gap-[42px] items-center justify-between ">
                <div className="leftfade">
                  {" "}
                  <img src="/images/blog/block-chain-blog.jpg" alt="Top Blockchain Development Companies in London" className="" width="1262px" height="764px" />
                </div>
                <div className="992px:mt-0 mt-7 max-w-[650px] rightfade">
                  <button className="bg-[rgba(51,51,51);]  rounded-[100px] text-text_14px  text-white  lg:px-12 lg:py-2 px-7 py-3">
                    <Link to="">Blockchain Development</Link>
                  </button>
                  <h3 className="mt-[18px] mb-[14px]  font-medium  text-white">
                    <Link to='/top-blockchain-development-companies-in-London'>Top Blockchain Development Companies in London - 2024</Link>
                  </h3>

                  {/* <p className="text-lg lg:text-2xl leading-[100%] font-medium  text-left pr-3 ">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p> */}
                  <p className="mt-[18px] mb-[18px] text-lg font-medium text-[#7D7D7D]">
                    12 Jun 2024
                  </p>
                  <div className="flex max-455:flex-col gap-4 w-full">
                    <button className="text-base font-medium rounded-[4px] py-1 border border-[#7D7D7D] px-5 mt-2">
                      <Link to="">#LondonBlockchain</Link>
                    </button>
                    <button className="text-base font-medium rounded-[4px] py-1 border border-[#7D7D7D] px-5  xl:ml-2 mt-2">
                      <Link to="">#BlockchainDevelopment </Link>
                    </button>
                  </div>
                </div>
              </div>

              <div className="Newblog_top flex flex-col md:flex-row gap-5 md:flex-nowrap  flex-wrap  mt-[50px]">
                <div className="bottomfadescroll md:w-4/12 max-md:mt-5">
                  {" "}
                  <Link to='/top-ten-website-design-companies-in-london'>

                    <div className="relative w-full">
                      <img
                        src="/images/blog/top-ten-website.jpg"
                        alt="Top 10 Website Design Companies in London"
                        className="w-full "
                      />
                      <button className="absolute bottom-3 left-3 mt-[30px] bg-[rgba(0,0,0,0.6);] rounded-full text-base font-normal text-white py-2 px-5 ">
                        <Link to="">Web Design</Link>
                      </button>
                    </div>
                    <h3 className="my-[10px] text-title_22px font-medium text-white line-clamp-2 line-clamp-2">
                      Top 10 Website Design Companies in London
                    </h3>
                    {/* <p className="text-lg  font-normal">
                    Lorem Ipsum is simply dummy text
                  </p>
                  <p className="mt-[14px] mb-[9px] text-lg font-medium text-[#7D7D7D]">
                    12 Jun 2024
                  </p> */}
                  </Link>

                  <div className="flex flex-col gap-3 w-full">
                    <button className="text-base font-medium rounded-[4px] py-1 border border-[#7D7D7D] px-5 mb-2">
                      <Link to="">#WebsiteDesignCompanies </Link>
                    </button>
                    <button className="sm:mt-0 text-base font-medium rounded-[4px] py-1 border border-[#7D7D7D] px-5">
                      <Link to="">#WebDesignLondon </Link>
                    </button>
                  </div>
                </div>

                <div className="bottomfadescroll md:w-4/12 max-md:mt-5">
                  {" "}
                  <Link to='/how-to-choose-a-web-development-company-in-the-UK-for-your-project'>

                    <div className="relative w-full">
                      <img
                        src="/images/blog/web-company-for-you.jpg"
                        alt=" Web Development Company in UK"
                        className="w-full "
                      />
                      <button className="absolute bottom-3 left-3 mt-[30px] bg-[rgba(0,0,0,0.6);] rounded-full text-base font-normal text-white  py-2 px-5">
                        <Link to="">Web Development</Link>
                      </button>
                    </div>
                    <h3 className="my-[10px] text-title_22px font-medium text-white line-clamp-2 line-clamp-2">
                      How to Choose a Web Development Company in the UK for Your Project
                    </h3>
                    {/* <p className="text-lg  font-normal">
                    Lorem Ipsum is simply dummy text
                  </p> */}
                    {/* <p className="mt-[14px] mb-[9px] text-lg font-medium text-[#7D7D7D]">
                    12 Jun 2024
                  </p> */}
                  </Link>

                  <div className="flex flex-col gap-3 w-full ">
                    <button className="text-base font-medium rounded-[4px] py-1 border border-[#7D7D7D] px-5 mb-2">
                      <Link to="">#UKWebDevelopment </Link>
                    </button>
                    <button className="sm:mt-0 text-base font-medium rounded-[4px] py-1 border border-[#7D7D7D] px-5">
                      <Link to="">#WebDevelopmentCompany</Link>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <Newblog /> */}
          <div className="items-center justify-center hidden">
            <div className="flex items-center mt-[58px] gap-2">
              <button className=" border border-black text-lg rounded-[10px] lg:px-12 lg:py-[14px] px-6 py-3">
                Load more
              </button>
              <div className="lg:w-6 lg:h-[67px] w-3 h-[50px] bg-black rounded-[10px]" />
            </div>
          </div>
          {/* <Letsdesign /> */}
          {/* <Footer /> */}
          {/* </Layout> */}
        </div>
      </div >
    </Layout>
  );
}

export default Blog;
