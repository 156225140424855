import { useGSAP } from "@gsap/react";
import gsap from "gsap"; import React from "react";
import "./style.scss";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

function GradientBalls() {
  useGSAP(() => {
    // background movement
    gsap.fromTo(
      ".child-1",
      {
        xPercent: 0
      },
      {
        xPercent: -100,
        repeat: -1,
        duration: 10,
        yoyo: true,
        ease: "none"
      }
    );
    gsap.to(".child-2", {
      repeat: -1,
      duration: 10,
      xPercent: 100,
      yoyo: true,
      ease: "none"
    });
    // const mm = gsap.matchMedia("");
    // mm.add("(min-width:1024px)", () => {
    // fadeup-withscroll-faderight-fadeleft
    gsap.set('.rightfade,.leftfade,.bottomfadescroll,.topfadescroll,.bottomfade,.background-grad', {
      willChange: "opacity, transform",
    });
    const boxesright = gsap.utils.toArray(".rightfade");
    boxesright.forEach((box) => {
      gsap.from(box, {
        opacity: 0,
        x: 150,
        duration: 1,
        scrollTrigger: {
          trigger: box,
          start: "top 90%",
          end: "100% 100%",
          toggleActions: "play none none reset"
          // scrub: 1
        }
      });
    });
    const boxesleft = gsap.utils.toArray(".leftfade");
    boxesleft.forEach((box) => {
      gsap.from(box, {
        opacity: 0,
        x: -150,
        duration: 1,
        scrollTrigger: {
          trigger: box,
          start: "top 90%",
          end: "100% 100%",
          toggleActions: "play none none reset"
          // scrub: 1
        }
      });
    });
    const boxesbottomscroll = gsap.utils.toArray(".bottomfadescroll");
    boxesbottomscroll.forEach((box) => {
      gsap.from(box, {
        opacity: 0,
        y: 150,
        duration: 1,
        scrollTrigger: {
          trigger: box,
          // markers: true,
          start: "top 91%",
          end: "100% 100%",
          toggleActions: "play none none reset"

          // scrub: 1
        }
      });
    });

    const boxestopscroll = gsap.utils.toArray(".topfadescroll");
    boxestopscroll.forEach((box) => {
      gsap.from(box, {
        opacity: 0,
        y: -150,
        duration: 1,
        scrollTrigger: {
          trigger: box,
          // markers: true,
          start: "top 90%",
          end: "100% 100%",
          toggleActions: "play none none reset"

          // scrub: 1
        }
      });
    });
    const boxesbottom = gsap.utils.toArray(".bottomfade");
    boxesbottom.forEach((box) => {
      gsap.from(box, {
        opacity: 0,
        y: 150,
        duration: 1
      });
    });
    const bg = document.querySelector(".background-grad");
    if (bg) {
      const bgRotate = () => {
        gsap.to(bg, {
          rotation: "-360",
          repeat: -1,
          duration: 15,
          ease: "none"
        });
      };
      bgRotate();
    }
  });

  return (
    <div className="box-main fixed z-0 top-0">
      {/* <div className="box-1 rounded-full"> */}
      <div className="child-1 will-change-transform">
        <img src='/images-new/yellow-blur.png' alt='Yellow Blur' width='966' height='750' />
      </div>
      <div className="child-2 will-change-transform">
        <img src='/images-new/gray-blur.png' alt='Gray Blur' width='966' height='829' />
      </div>
      {/* </div> */}
    </div>
  );
}

export default GradientBalls;
